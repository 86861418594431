<template>
   <div class="container" data-v-35cf7caa="">
        <div class="row" data-v-35cf7caa="">
            <div class="col-12" data-v-35cf7caa="">
                <div class="sign__content" style="min-height: 91vh;">
                    <form class="sign__form sign__trailer card" @submit.prevent="unsubscribe()">
                        <a class="sign__logo" style="margin-bottom: 10px;">
                            <img src="../../assets/zain_gray.png" style="margin-top: 15px;margin-bottom: 20px;">
                        </a>
                        <p style="color: #fff">إلغاء الإشتراك </p>
                        <center data-v-35cf7caa="">
                            <div class="sign__group" style="justify-content: center;" data-v-35cf7caa="">
                                <button class="sign__btn button pointer header-btn" style="width: 100%;">
                                    <span v-if="loading" class="px-1 ">جاري الغاء الاشتراك</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="arabickufi">الغاء الاشتراك</span>
                                </button>
                                <a @click="goBack" class="sign__btn button pointer header-btn" style="width: 100%;">رجـــــوع</a>
                            </div>
                        </center>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
   data() {
     return {
       loading: false,
     };
   },
    mounted() {
       if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
       }
    },
   methods: {
      goBack() {
         return this.$router.go(-1)
      },
      async unsubscribe () {
        const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
          this.loading = true;
        await HTTPDSP.post(`DSPUnsubscribe.php?msisdn=`+this.$cookie.getCookie("msisdn")).then((response) => {
          if (response.data.status == 0) {
            toast.error("انت لست مشترك في نادي الضحك", { timeout: 2000 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من نادي الضحك", { timeout: 2000 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>